import { cloneDeep } from 'lodash';
import { derived, get, writable } from 'svelte/store';
import { PageTypeEnum } from '@enums/PageTypeEnum.js';
import { userStore } from '@stores/user/user.js';
import { activeRoute } from '@stores/routes/routes.js';

export function synchronisablePageTypeStore(defaultValue, synchronisableMapCallback = null) {
    const { subscribe, update } = writable(
        synchronisableMapCallback ? synchronisableMapCallback(defaultValue) : getDefaultSynchronisableMap(defaultValue),
    );

    const setValueForPageType = (pageType, value) => {
        update((storeValue) => {
            storeValue[pageType] = value;
            return storeValue;
        });
    };

    const setValue = (value) => {
        setValueForPageType(getSyncKey(), value);
    };

    const deletePageType = (pageType) => {
        update((storeValue) => {
            delete storeValue[pageType];
            return storeValue;
        });
    };

    return {
        subscribe,
        set: setValue,
        setValueForPageType,
        deletePageType,
        update,
    };
}

export function synchronisablePageTypeObjectStore(defaultValue = {}, synchronisableMapCallback = null) {
    const { subscribe, set, setValueForPageType, deletePageType, update } = synchronisablePageTypeStore(defaultValue, synchronisableMapCallback);

    const setKeyValueForPageType = (pageType, key, value) => {
        update((storeValue) => {
            storeValue[pageType][key] = value;
            return storeValue;
        });
    };

    const deleteKeyForPageType = (pageType, key) => {
        update((storeValue) => {
            delete storeValue[pageType][key];
            return storeValue;
        });
    };

    const setKeyValue = (key, value) => {
        setKeyValueForPageType(getSyncKey(), key, value);
    };

    const deleteKey = (key) => {
        deleteKeyForPageType(getSyncKey(), key);
    };

    return {
        subscribe,
        set,
        setValueForPageType,
        setKeyValue,
        setKeyValueForPageType,
        deleteKeyForPageType,
        deleteKey,
        deletePageType,
        update,
    };
}

export function derivedSynchronisableStore(synchronisableStore) {
    return derived([userStore, activeRoute, synchronisableStore], ($storeValues) => {
        const [$userStore, $activeRoute, $synchronisableStore] = $storeValues;

        return $userStore?.sync_filter ? $synchronisableStore.sync : $synchronisableStore[$activeRoute.pageType];
    });
}

function getSyncKey() {
    const route = get(activeRoute);
    const user = get(userStore);
    return user.sync_filter ? 'sync' : route.pageType;
}

export function getDefaultSynchronisableMap(defaultValue) {
    const defaultsMap = {
        sync: cloneDeep(defaultValue),
    };
    PageTypeEnum.cases.forEach(caseValue => {
        if (caseValue.includes('analytics.')) {
            defaultsMap[caseValue] = cloneDeep(defaultValue);
        }
    });

    return defaultsMap;
}
