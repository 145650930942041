import { writable } from 'svelte/store';
import { derivedSelectedRatingsStore } from '@analytics/stores/filters/ratings.js';
import { availableRatingsStore } from '@stores/availables/ratings.js';
import { attributesConfigStore } from '@stores/configs/attributes.js';

export const translationsStore = (() => {
    const { subscribe, set, update } = writable({});

    const translate = (key) => {
        // remove placeholder interpolation from legacy Portal app
        key = key.replace('{{', '').replace('}}', '');

        let value;
        subscribe((state) => {
            value = state[key] ?? key;
        })();
        return value;
    };

    const translateReplaceValues = (key, attribute = null) => {
        let availableAttributes;
        attributesConfigStore.subscribe((state) => {
            availableAttributes = state;
        });

        let availableRatings;
        availableRatingsStore.subscribe((state) => {
            availableRatings = state;
        });

        let selectedRatings;
        derivedSelectedRatingsStore.subscribe((state) => {
            selectedRatings = state;
        });

        let ratingName = translationsStore.translate('filter_multiple_companies');
        if (selectedRatings.length === 1) {
            ratingName = availableRatings.find((rating) => rating.id === selectedRatings[0])?.name;
        }

        const ratingsRegex = /(%2\$s|%s)/g;

        let result = translationsStore.translate(key).replace(ratingsRegex, ratingName);

        if (attribute) {
            result = result.replace('%1$s', translationsStore.translate(availableAttributes[attribute].name));
        }

        return result;
    };

    return {
        subscribe,
        set,
        update,
        translate,
        translateReplaceValues,
    };
})();
