<script>
    export let layout;
</script>

{#if layout}
    <svelte:component this={layout}>
        <slot />
    </svelte:component>
{:else}
    <slot/>
{/if}
