import axios from 'axios';

export class AuthResource {
    login(login, password, remember) {
        return axios.post('/login', {
            login,
            password,
            remember,
        });
    }

    logout() {
        window.location.href = '/logout';
    }

    requestForgottenPassword(email) {
        return axios.post('/forgot-password', { email });
    }

    resetPassword(email, password, passwordConfirmation, token) {
        return axios.post('/reset-password', {
            email,
            password,
            password_confirmation: passwordConfirmation,
            token,
        });
    }

    newPassword(currentPassword, newPassword, confirmPassword) {
        return axios.post('/new-password', {
            current_password: currentPassword,
            password: newPassword,
            password_confirmation: confirmPassword,
        });
    }
}
