<script>
    import page from 'page';
    import LayoutWrapper from '@components/router/LayoutWrapper.svelte';
    import { activeModule, activeRoute, activeRouteContext } from '@stores/routes/routes.js';
    import { userStore } from '@stores/user/user.js';

    export let routes;

    init();

    function init() {
        routes.forEach(route => {
            page(
                route.path,
                (context, next) => (route.redirectTo ? page.redirect(route.redirectTo) : next()),
                (context) => {
                    $activeModule = route.moduleType;
                    $activeRoute = route;
                    $activeRouteContext = context;

                    if ($userStore?.id) {
                        window.Appcues.identify($userStore.id, // unique, required
                            {
                                name: $userStore.first_name + ' ' + $userStore.last_name,
                                username: $userStore.username,
                                email: $userStore.email,
                                created_at: $userStore.created_at,
                                language: $userStore.language_id,
                            },
                        );
                    }
                },
            );
            page.start();
        });
    }
</script>

{#if $activeRoute}
    <LayoutWrapper layout={$activeRoute.module}>
        <svelte:component this={$activeRoute.component} />
    </LayoutWrapper>
{/if}
