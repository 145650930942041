import { derived, writable } from 'svelte/store';

export const activeModule = writable(null);
export const activeRoute = writable(null);
export const activeRouteContext = writable(null);

export const activeRouteQueryStringObject = derived([activeRouteContext], ($storeValues) => {
    const [
        $activeRouteContext,
    ] = $storeValues;

    const queryStringObject = {};
    const queryString = new URLSearchParams($activeRouteContext.querystring);
    for (const [key, value] of queryString) {
        queryStringObject[key] = value;
    }

    return queryStringObject;
});
